<script>
export default {
    name: "UvedListitem",

    props: {
        item: {
            type: Object,
            required: true
        },

        columns: {
            type: Array,
            required: true
        },

        userBlock: {
            type: Boolean,
            required: true
        }
    },

    computed: {
        activeColumns() {
            return this.columns.filter(column => {
                return column.active
                    && column.prop !== 'responsibles'
                    && column.prop !== 'title'
                    && column.prop !== 'deadline'
            });
        }
    },

    methods: {
        deleteUved(item) {
            this.$emit('deleteItem', item);
        }
    }
}
</script>

<template>
    <div class="item">
        <div class="item__header">
            <div class="item__status small-text" :style="{ backgroundColor: item.color }">{{ item.task_status }}</div>
            <div class="item__created_at text-color-light small-text">{{ item.created_at }}</div>
        </div>

        <div class="item__description">
            <div class="item__id text-color-light small-text">#{{ item.id }}</div>
            <div class="item__name">
                <router-link :disabled="userBlock" text plain :to="'/task/' + item.uuid + '/show'" class="cursor-pointer font_weight_500 button_link">
                    {{ item.title }}
                </router-link>
                <v-icon  @click="deleteUved(item)" :title="$t('delete')">
                    mdi-delete-outline mdi-18px
                </v-icon>
            </div>

            <div v-if="item.text" class="item__note text-color-light">{{ item.text }}</div>

        </div>

        <div class="item__data" v-if="activeColumns.length > 0">
            <div class="text-info" v-for="column in activeColumns" :key="column.name">
                <div class="text-info__label">
                    <div>{{ column.label }}</div>
                </div>
                <div class="text-info__value">
                    <slot :name="column.prop" :item="item">
                        <div>{{ item[column.prop] }}</div>
                    </slot>
                </div>
            </div>
        </div>

        <div class="item__footer">
            <div class="item__deadline">
                <div class="text-info">
                    <div class="text-info__label">
                        <div>{{ $t('deadline') }}</div>
                    </div>
                    <div class="text-info__value">
                        <div>{{ item.deadline }}</div>
                    </div>
                </div>
            </div>

            <div class="item__responsibles">
                <div v-if="item.responsibles && item.responsibles.length === 1 && item.responsibles[0] && item.responsibles[0].name">
                    <v-list subheader two-line class="background_none py-0">
                        <v-list-item class="px-0 py-0 my-0 min_height_auto">
                            <v-list-item-avatar  size="36" class="my-0 py-0 cursor-pointer" >
                                <img v-if="item.responsibles[0].photo !== '/img/avatar.png'" :src="item.responsibles[0].photo" :alt="item.responsibles[0].name">
                                <v-icon v-else color="primary" size="22">
                                    ${{'settingsIcon'}}
                                </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content class="py-0 title_subtitle">
                                <v-list-item-title v-text="item.responsibles[0].name" ></v-list-item-title>
                                <v-list-item-subtitle v-text="formatPhoneNumber(item.responsibles[0].phone)"></v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </div>

                <div v-if="item.responsibles && item.responsibles.length > 1">
                    <div class="avatars cursor-pointer">
                    <span class="avatar" v-for="(item, index) in item.responsibles"  :key="index">
                          <v-avatar size="36" >
                              <img v-if="item.photo !== '/img/avatar.png'" :title="item.name" class="" :src="item.photo" :alt="item.name">
                              <img v-else :title="item.name" class="avatar_border avatar_border_prime" src="/img/avatar.svg" :alt="item.name">
                          </v-avatar>
                    </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.item {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    margin-bottom: $spacer * 4;
}

.item__header {
    padding-top: $spacer * 4;
    padding-left: $spacer * 4;
    padding-right: $spacer * 4;
    margin-bottom: $spacer * 4;

    display: flex;
    justify-content: space-between;
}

.item__description {
    padding-left: $spacer * 4;
    padding-right: $spacer * 4;
    margin-bottom: $spacer * 4;
}

.item__status {
    padding: 2px 8px;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
}

.item__id {
    margin-bottom: $spacer * 2;
}

.item__name {
    margin-bottom: $spacer * 2;
}

.item__data {
    padding-left: $spacer * 4;
    padding-right: $spacer * 4;
    padding-bottom: $spacer * 4;
    overflow-y: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    position: relative;
}

.item__footer {
    border-top: 1px solid #E7E7E7;
    padding: $spacer * 4;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.item__deadline {
    flex-grow: 1;
}

.item__responsibles {
    max-width: 50%;
}
</style>
